.navbar {
	background: black;
	overflow: hidden;
	position: sticky;
	top: 0;
	z-index: 999;
}

.navbar ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.navbar li a {
	display: block;
	color: white;
	text-align: center;
	padding: 8px 18px;
	text-decoration: none;
	font-weight: 500;
	margin: 0 7px;
	transition: 0.3s all ease;
	font-family: "Poppins", sans-serif;
}

.navbar li a:hover {
	background: white;
	color: black;
	border-radius: 50px;
}

.brand-name {
	font-size: 28px;
	font-weight: 700;
	margin-bottom: 0;
	font-family: "Poppins", sans-serif;
}

.brand-icon {
	width: 35px;
	height: 35px;
}
