/* DEFAULT SETTING */
/* ============== */

@media (max-width: 576px) {
	.brand-name {
		font-size: 18px !important;
	}
	.brand-icon {
		width: 25px !important;
		height: 25px !important;
	}
	.navbar-toggler {
		transform: scale(0.7);
	}
	.navbar-toggler:focus {
		box-shadow: none !important;
	}
	#navbarColor01 {
		padding: 20px;
		margin-top: 5px;
	}
	.nav-item {
		margin-bottom: 5px;
	}
	/* Nav Section End */

	.Hero h1 {
		font-size: 35px !important;
		letter-spacing: 0 !important;
		padding: 0 !important;
	}
	.content {
		top: 120px !important;
	}
	/* Hero Section End*/
	#album {
		transform: scale(1) !important;
	}
	.card img {
		height: 160px !important;
	}
	.card button {
		font-size: 14px !important;
	}
	/* Category End */

	.Display {
		margin: 0 !important;
	}
	.Display input {
		width: 80% !important;
		margin: 30px auto;
		margin-bottom: 0 !important;
	}

	.product-card img {
		height: 250px !important;
	}
	.product-card h5 {
		font-size: 16px !important;
	}
	.product-card p {
		font-size: 14px;
	}
	.product-card button {
		font-size: 15px !important;
	}
	.product-card {
		transform: scale(0.86) !important;
		height: 400px !important;
	}
	/* Feature Section End */
	#form-text {
		font-size: 20px !important;
	}
	#carousel {
		height: 20vh !important;
	}
	/* Form and Carousel End */
	.copyright {
		font-size: 18px !important;
		margin-top: 5px;
	}
	.footer-icons {
		transform: scale(0.7);
	}
	.footer-container {
		flex-direction: column !important;
	}
	/* Contact Page Here */

	#contact-page {
		padding: 0 !important;
	}
	#contact-page h2 {
		display: none !important;
	}

	.contact-center {
		flex-direction: column !important;
		padding: 0 !important;
		height: 100vh;
		margin-bottom: 20px;
	}

	.about-icons {
		width: 100% !important;
		transform: scale(0.5);
	}
	#grid {
		margin: 30px 0px !important;
	}
	.pagination {
		transform: scale(0.85);
		margin-top: 20px !important;
		margin-bottom: 40px !important;
	}
	/* Enquire Page */
	.enquire-head {
		flex-direction: column !important;
		text-align: center;
	}
	.enquire-head img {
		margin: 20px 0;
	}
	.enquire-head h4 {
		font-size: 18px !important;
	}
	.enquire-inputs input {
		width: 95% !important;
	}
	.suggest ul {
		padding: 0;
		text-align: center;
	}
	/* Detail Page */
	.detail-page {
		flex-direction: column;
	}
	.left {
		flex-direction: column-reverse;
	}
	.left img {
		width: 350px !important;
		height: 420px !important;
	}
	.min-img {
		flex-direction: row !important;
		flex-wrap: wrap;
		justify-content: space-between !important;
		margin-bottom: 10px;
	}
	.min-img img {
		height: 85px !important;
		width: 90px !important;
	}
	.right {
		width: 100% !important;
		text-align: center;
	}
	.right h3 {
		padding: 10px 50px !important;
	}
	#contact-page {
		height: 135vh;
	}
}

/* SMALL DEVICES */
/* ============ */

@media (min-width: 576px) and (max-width: 991px) {
}

/* LARGE DEVICES */
/* ============= */

@media (min-width: 992px) and (max-width: 1599px) {
}
