.card button {
	width: 100%;
}

#album {
	transform: scale(0.9);
}

.card {
	height: 100%;
}

.card img {
	height: 350px;
	width: 100%;
	object-fit: cover;
}

.col {
	transform: scale(0.92);
}
