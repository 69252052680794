.Hero {
	height: 100vh;
}
.Hero img {
	position: relative;
	object-fit: cover !important;
	width: 100%;
	height: 100%;
}
.content {
	position: absolute;
	top: 60px;
	z-index: 888;
}

.hero-img {
	object-fit: cover;
	object-position: center;
	height: 100vh;
	width: 85%;
	transform: scale(1.3);
}

.Hero h1 {
	font-size: 60px;
	letter-spacing: 10px;
	font-family: "Rochester", cursive;
	font-weight: 800 !important;
	margin: 12px;
	padding: 0 50px;
	color: white;
}

#Herobtn {
	padding: 7px 120px;
	font-weight: bold;
}
