.left {
	width: 60%;
	margin-left: 60px;
}
.right {
	width: 40%;
}

.left img {
	width: 550px;
	height: 600px;
	object-fit: cover;
}
.min-img {
	flex-direction: column;
}

.min-img img {
	height: 72px;
	width: 84px;
	object-fit: cover;
	margin: 7px 0;
	cursor: pointer;
}

.min-img img[alt=""] {
	display: none;
}

.right h3 {
	padding-right: 100px;
}

#accordionExample {
	max-width: 1100px !important;
}
