.pagination {
	justify-content: center;
	margin-top: 70px;
}

.pagination a {
	padding: 10px 20px;
	background: lightblue;
	border-radius: 10px;
	margin: 0 10px;
	text-decoration: none;
}

.Display img {
	width: 100%;
	height: 170px;
	object-fit: cover;
	cursor: pointer;
}

.Display {
	margin: 30px 150px;
}

.product-card {
	box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
	width: 250px;
	height: 450px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	transform: scale(0.95);
	border-radius: 10px;
}

.product-card a {
	width: 100%;
}

.product-card img {
	object-fit: cover;
	width: 100% !important;
	height: 300px;
}
