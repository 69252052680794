@import url("https://fonts.googleapis.com/css2?family=Rochester&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
		"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.contact {
	height: 100vh;
	display: flex;
	align-items: center;
}

.contact-f {
	transform: scale(0.9);
}

#contact-page {
	height: 100vh;
}

#About-title {
	font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
	border-bottom: 1px solid #3333;
}

.about-icons {
	transform: scale(0.75);
}

.footer-icons {
	transform: scale(0.8);
}

.contact-img-logo {
	width: 100% !important;
	height: 450px !important;
}
